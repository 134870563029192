import React from "react"

import Layout from "../layouts/en"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.en"
import HeroSection from "../components/HeroSection"
import WhyJubarte from "../components/WhyJubarte"
import HowItWorks from "../components/HowItWorks"
import Plans from "../components/Plans.en"
import FAQ from "../components/FAQ"
import Portfolio from "../components/Portfolio"
import JubarteIsFor from "../components/JubarteIsFor"

//Content
import plans from "../content/Plans-en.json"

const planImages = {
  beachPlanIcon: require("../assets/svg/flat-icons/jd-icons-plans-star.svg"),
  beachPlanDivider: require("../assets/svg/flat-icons/jd-divider-plans_blue.svg"),
  seaPlanIcon: require("../assets/svg/flat-icons/jd-icons-plans-fish.svg"),
  seaPlanDivider: require("../assets/svg/flat-icons/jd-divider-plans_blue.svg"),
  oceanPlanIcon: require("../assets/svg/flat-icons/jd-icons-plans-jelly.svg"),
  oceanPlanDivider: require("../assets/svg/flat-icons/jd-divider-plans_agua.svg"),
}

const heroContent = {
  title: "Unlimited",
  subtitle: "Graphic Design",
  titleClass: "jd-special-hero",
  subtitleClass: "jd-uppercase-hero",
  text: "Have a team of designers working for you! No contracts, no interviews, no drama!",
  btnType: "internal",
  btnText: "View Plans",
  btnLink: "/",
  loginButtonText: "Login",
  locale: "en",
  svg: require("../assets/svg/flat-icons/jd-hero.svg"),
}

const whyContent = [
  {
    svg: require("../assets/svg/flat-icons/jd-icons-unlimited-design.svg"),
    title: `UNLIMITED DESIGNS`,
    text: `That's right, unlimited designs and reviews! You can request as many designs as you want, change the concept as you wish and ask for as many revisions as you need until you are 100% happy with your project!`,
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-its-yours.svg"),
    title: `WHAT'S YOURS IS YOURS!`,
    text: `Everything we create for you is yours. You have all rights and licenses for all designs we create. If your plan includes stock photos subscription you will also receive the licenses of the photos that are used.`,
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-no-contracts.svg"),
    title: `NO CONTRACTS`,
    text: `Subscriptions are month-to-month with no contracts, allowing you to change your plan at any time. If you wish to cancel your subscription, we will miss you but you can do it quickly and without questions on our website."`,
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-tools.svg"),
    title: `DESIGN TOOLS`,
    text: `We want everything to come out perfect and that you have access to the best tools! That's why we use the latest versions of Adobe Creative Suite, which includes Photoshop, Illustrator, and InDesign — the most commonly tools used for creative projects.`,
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-support-chat-email.svg"),
    title: `CHAT & E-MAIL SUPPORT`,
    text: `We'll love to hear more about your questions and how we can help you. We have real-time chat and will answer to any e-mails within 24 hours. You can also talk to your designer and account manager using these same channels.`,
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-safe-data.svg"),
    title: `YOUR DATA IS ALWAYS SAFE`,
    text: `We appreciate the trust you place in us and for that we are responsible for protecting and treating your information with utmost care and security. Sleep peacefully knowing your data is safe with us!`,
  },
]

const howItWorksSteps = [
  {
    title: "Request",
    text:
      "Once your on-boarding is complete, just send us an email or fill out the form on our website describing the requirements of your project.",
  },
  {
    title: "Design",
    text:
      "Your designer will work and send the design for your approval. We will make all the changes you ask until everything is absolutely perfect.",
  },
  {
    title: "Delivery",
    text:
      "Once you have approved your project, download the files on our website and we will be ready to start your next project!",
  },
]

const faqContent = [
  {
    title: `What do you mean by unlimited design?`,
    text: `"You can request as many designs as you wish, and your orders will be processed one by one in the order they are received, as soon as possible, ie we will work on one demand at a time and when you are satisfied with your current request, we will be ready to start your next project."`,
  },
  {
    title: `Are the revisions also unlimited?`,
    text: `"Yes! We want you to be completely happy with your design, ask for as many adjustments as you need, and we'll work on your project until you're satisfied!"`,
  },
  {
    title: `How long does it take to complete a design task?`,
    text: `"We'll begin working on your order within a few business hours after receiving it, and then deliver your request to be reviewed within 2 to 5 business days, depending on your plan and the complexity of your request."`,
  },
  {
    title: `What if I have a complex design request?`,
    text: `"Tell us what you need! We will evaluate and if we can attend, we provide a personalized quotation of time."`,
  },
  {
    title: `How long does a review take?`,
    text: `"Normally, each review takes at least one working day, however it may happen that you may need a more complex change, and that it will take more time, and it might take more time to complete. In that case we will provide a 'time quote for adjustment'."`,
  },
  {
    title: `So ... and all this without contracts?`,
    text: `"Exactly, no contracts !! We work with a subscription model month by month which means that, if you want to use our services for only a month, that's fine."`,
  },
  {
    title: `Can I change my plan at any time?`,
    text: `"Of course you can change your plan whenever you need. Just contact us at support@jubarte.design and we will be happy to change it for you."`,
  },
  {
    title: `Are there any hidden fees?`,
    text: `"Absolutely no hidden fees. The price of the plan is exactly what you pay monthly. All taxes and fees are included in this price as well."`,
  },
  {
    title: `What kind of design you don't do?`,
    text: `"Here are some examples of projects we don't do: video editing, animations, CAD, 3D work, programming and coding. Still, if you want to check out a specific project, don't think twice, and just contact us"`,
  },
]

const portfolioTabsContent = [
  {
    title: "All",
    ariaLabel: "Show complete portfolio",
  },
  {
    title: "Logo & Identity",
    ariaLabel: "Show Logo & Identity portfolio",
  },
  {
    title: "Web-design & Media",
    ariaLabel: "Show Web-design & Media portfolio",
  },
  {
    title: "Graphic Design",
    ariaLabel: "Show Graphic Design portfolio",
  },
  {
    title: "Art & Illustrations",
    ariaLabel: "Show Art & Illustrations portfolio",
  },
]

const portfolioImageGridContent = [
  {
    key: 1,
    alt: "Graphic Design - 40ft Shipping Container Homes - Magazine",
    imageJPG: require("../assets/images/760x720/graphic_design_40ft_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_40ft_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 2,
    alt: "Logo Design - Margaret River",
    imageJPG: require("../assets/images/760x540/logo_design_margaret_river.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_margaret_river.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 3,
    alt: "Graphic Design - René Bueno - Business Card",
    imageJPG: require("../assets/images/760x540/graphic_design_rene_bueno.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_rene_bueno.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 4,
    alt: "App Design - Hikefit",
    imageJPG: require("../assets/images/1520x1080/app_design_hikefit.jpg"),
    imageWEBP: require("../assets/images/1520x1080/app_design_hikefit.webp"),
    category: [portfolioTabsContent[2]],
  },
  {
    key: 5,
    alt: "Logo Design - Logyc",
    imageJPG: require("../assets/images/760x540/logo_design_logyc.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_logyc.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 6,
    alt: "Graphic Design - Riso di Cielo - Risotto Packaging Design",
    imageJPG: require("../assets/images/760x720/graphic_design_riso_di_cielo.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_riso_di_cielo.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 7,
    alt: "Graphic Design - Barro Bueno - Social Media Post",
    imageJPG: require("../assets/images/760x540/graphic_design_barro_bueno_3.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_barro_bueno_3.webp"),
    category: [portfolioTabsContent[2], portfolioTabsContent[3]],
  },
  {
    key: 8,
    alt: "Graphic Design - OneStone - Beverage Packaging and Brand",
    imageJPG: require("../assets/images/760x720/graphic_design_onestone_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_onestone_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 9,
    alt: "Logo Design - Barro Bueno",
    imageJPG: require("../assets/images/760x540/logo_design_barro_bueno.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_barro_bueno.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 10,
    alt: "Graphic Design - Logyc Stationery System and Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_logyc.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_logyc.webp"),
    category: [portfolioTabsContent[1], portfolioTabsContent[3]],
  },
  {
    key: 11,
    alt: "Graphic Design - Margaret River - Brochure",
    imageJPG: require("../assets/images/760x540/graphic_design_margaret_river_1.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_margaret_river_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 12,
    alt: "Graphic Design - Skafundro - Illustration and Label",
    imageJPG: require("../assets/images/760x540/graphic_design_skafundro.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_skafundro.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 13,
    alt: "Graphic Design - Brimir - Illustration and Business Card",
    imageJPG: require("../assets/images/760x540/graphic_design_brimir_3.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_brimir_3.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 14,
    alt: "Graphic Design - 130 Book - Illustration and Book Design",
    imageJPG: require("../assets/images/760x540/graphic_design_130_book.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_130_book.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 15,
    alt: "Web Design - Find Your Fellow",
    imageJPG: require("../assets/images/1520x1080/web_design_find_your_fellow_2.jpg"),
    imageWEBP: require("../assets/images/1520x1080/web_design_find_your_fellow_2.webp"),
    category: [portfolioTabsContent[2]],
  },
  {
    key: 16,
    alt: "Graphic Design - OneStone - Beverage Packaging and Brand",
    imageJPG: require("../assets/images/760x720/graphic_design_onestone_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_onestone_2.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 17,
    alt: "Graphic Design - Brimir Gift Card - Illustration and Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_brimir_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_brimir_1.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 18,
    alt: "Graphic Design - Ancient Codes Logo - Toy Packaging",
    imageJPG: require("../assets/images/760x720/graphic_design_ancient_codes_logo.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_ancient_codes_logo.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 19,
    alt: "Graphic Design - Barro Bueno - Bag and Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_barro_bueno_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_barro_bueno_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 20,
    alt: "Logo Design - One Stone",
    imageJPG: require("../assets/images/760x540/logo_design_onestone.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_onestone.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 21,
    alt: "Graphic Design - Brimir Packaging - Illustration and Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_brimir_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_brimir_2.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 22,
    alt: "App Design - Notto",
    imageJPG: require("../assets/images/760x720/app_design_notto.jpg"),
    imageWEBP: require("../assets/images/760x720/app_design_notto.webp"),
    category: [portfolioTabsContent[2]],
  },
  {
    key: 23,
    alt: "Graphic Design - Find Your Fellow - Tshirt and Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_find_your_fellow_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_find_your_fellow_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 24,
    alt: "Graphic Design - OneStone - Beverage Packaging and Brand",
    imageJPG: require("../assets/images/760x720/graphic_design_onestone_3.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_onestone_3.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 25,
    alt: "Graphic Design - Spirits of Lettering - Illustration and Poster",
    imageJPG: require("../assets/images/760x720/graphic_design_spirits_of_lettering.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_spirits_of_lettering.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 26,
    alt: "Logo Design - René Bueno",
    imageJPG: require("../assets/images/760x540/logo_design_rene_bueno.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_rene_bueno.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 27,
    alt: "Logo Design - Brimir",
    imageJPG: require("../assets/images/760x540/logo_design_brimir.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_brimir.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 28,
    alt: "Graphic Design - 40ft Shipping Container Homes - Magazine",
    imageJPG: require("../assets/images/760x720/graphic_design_40ft_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_40ft_2.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 29,
    alt: "Graphic Design - Cleusa Vicchiarelli - Mug",
    imageJPG: require("../assets/images/760x540/graphic_design_cleusa_vicchiarelli.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_cleusa_vicchiarelli.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 30,
    alt: "Graphic Design - Barro Bueno - Stamp and Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_barro_bueno_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_barro_bueno_2.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 31,
    alt: "Graphic Design - Mimicus - Illustration and Tag",
    imageJPG: require("../assets/images/760x540/graphic_design_mimicus.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_mimicus.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
]

const typingContent = [
  "businessmen",
  "investors",
  "start-ups",
  "big companies",
  "developers",
  "entrepreneurs",
  "digital agency",
  "bloggers",
  "freelancers",
]

const IndexPage = (props) => (
  <Layout location={props.location}>
    <Metomic />
    <SEO title="Unlimited Graphic and Web Design | Jubarte Design" locale="en" altLocale="pt" />
    <SplashScreens />
    <Header showNavLinks showLanguageSelector />
    <div className="home-wrapper">
      <HeroSection content={heroContent} scrollTo="Plans" />

      <JubarteIsFor firstTitle="Jubarte is made for" lastTitle="like you!" content={typingContent} />

      <WhyJubarte firstTitle="Why" mainTitle="Jubarte Design" content={whyContent} />

      <HowItWorks
        scrollTo="How"
        firstTitle="How it"
        mainTitle="Works"
        steps={howItWorksSteps}
        image={require("../assets/svg/flat-icons/jd-how.svg")}
      />

      <Plans scrollTo="Plans" plans={plans} planImages={planImages} comparePlans />

      <FAQ
        scrollTo="FAQ"
        firstTitle="Frequently Asked"
        mainTitle="Questions"
        content={faqContent}
        btnText="Learn More"
        btnLink="/en/help/"
      />

      <Portfolio
        scrollTo="Portfolio"
        firstTitle="Our"
        mainTitle="Portfolio"
        tabs={portfolioTabsContent}
        imageGrid={portfolioImageGridContent}
      />

      <div className="u-space-2-bottom"></div>
    </div>
  </Layout>
)

export default IndexPage
