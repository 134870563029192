import React, { Component } from "react"
import Slider from "react-slick"

import ComparePlans from "./ComparePlans.en.js"

export default class Plans extends Component {
  render() {
    const data = this.props

    // Plans Content
    const beachPlanContent = data.plans.beachPlanContent
    const seaPlanContent = data.plans.seaPlanContent
    const oceanPlanContent = data.plans.oceanPlanContent
    const planImages = data.planImages

    const slickSettings = {
      dots: true,
      className: "jd-slider",
      pauseOnHover: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            autoplay: true,
            infinite: true,
            initialSlide: 2,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            draggable: true,
            autoplay: true,
            infinite: true,
            initialSlide: 2,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 554,
          settings: {
            slidesToShow: 1,
            draggable: true,
            autoplay: true,
            infinite: true,
            initialSlide: 2,
            swipeToSlide: true,
          },
        },
      ],
    }

    // Dinamically show/hide the ComparePlans component based on props being passed by the page
    let showComparePlansComponent
    data.comparePlans ? (showComparePlansComponent = <ComparePlans />) : (showComparePlansComponent = <br />)

    return (
      <div name={data.scrollTo} className="plans-wrapper position-relative u-pricing-v2-wrapper z-index-2">
        <div className="container u-space-4">
          <div className="mb-9">
            <h2 className="jd-h2-titles text-center">
              {data.plans.firstTitle} <span className="font-weight-bold">{data.plans.mainTitle}</span>{" "}
              {data.plans.secondTitle}!
            </h2>
            <p className="text-center subtitle">{data.plans.subtitle}</p>
            <br />
          </div>

          <div className="u-space-2-bottom">
            <div id="pricingMonthly" data-target-group="idPricing">
              <div className="u-slick u-slick--gutters-3 z-index-2">
                <Slider {...slickSettings}>
                  <div className="slider">
                    <div className="jd-pricing-v2 text-center">
                      <header className="jd-pricing-v2__header">
                        <img
                          className="img-fluid"
                          style={{ padding: "5px" }}
                          width="47%"
                          src={planImages.beachPlanIcon}
                          alt="Beach plan icon"
                        />
                        <div className="jd-pricing-v2__header__title">
                          <span>{beachPlanContent.name}</span>
                        </div>
                        <span className="jd-card-headcopy">{beachPlanContent.text}</span>
                        <span className="d-block">
                          <span>
                            <span>{data.plans.currency} </span>
                            <span className="jd-plans-prices">{beachPlanContent.price}</span>
                          </span>
                          <span style={{ fontSize: ".8em" }}>/month</span>
                        </span>
                        <br />
                        <img
                          className="plans-section-divider"
                          style={{ display: "inline-block" }}
                          src={planImages.beachPlanDivider}
                          alt="divider"
                        />
                      </header>

                      <div className="jd-pricing-v2__content">
                        <ul className="list-unstyled mb-4">
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.design}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.revisions}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.brands}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.users}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.rightsIncluded}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.turnaround}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.dedicatedDesigner}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.dedicatedAccountManager}</li>
                          <li className="u-pricing-v2__list-item py-2">{beachPlanContent.dedicatedWebDesigner}</li>
                        </ul>
                      </div>

                      <div className="jd-pricing-v2__cta">
                        <button
                          type="button"
                          className="btn rounded-pill jd-btn-primary--air vrlps-trigger pt-1"
                          href={beachPlanContent.ourPlansLink}
                        >
                          {beachPlanContent.ourPlansText}
                        </button>
                      </div>

                      {showComparePlansComponent}
                    </div>
                  </div>

                  <div className="slider">
                    <div className="jd-pricing-v2 text-center">
                      <header className="jd-pricing-v2__header">
                        <img
                          className="img-fluid"
                          style={{ padding: "5px" }}
                          width="47%"
                          src={planImages.seaPlanIcon}
                          alt="Sea plan icon"
                        />
                        <div className="jd-pricing-v2__header__title">
                          <span>{seaPlanContent.name}</span>
                        </div>
                        <span className="jd-card-headcopy">{seaPlanContent.text}</span>
                        <span className="d-block">
                          <span>
                            <span>{data.plans.currency} </span>
                            <span className="jd-plans-prices">{seaPlanContent.price}</span>
                          </span>
                          <span style={{ fontSize: ".8em" }}>/month</span>
                        </span>
                        <br />
                        <img
                          className="plans-section-divider"
                          style={{ display: "inline-block" }}
                          src={planImages.seaPlanDivider}
                          alt="divider"
                        />
                      </header>

                      <div className="jd-pricing-v2__content">
                        <ul className="list-unstyled mb-4">
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.design}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.revisions}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.brands}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.users}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.rightsIncluded}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.turnaround}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.dedicatedDesigner}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.dedicatedAccountManager}</li>
                          <li className="u-pricing-v2__list-item py-2">{seaPlanContent.dedicatedWebDesigner}</li>
                        </ul>
                      </div>

                      <div className="jd-pricing-v2__cta">
                        <button
                          type="button"
                          className="btn rounded-pill jd-btn-primary--air vrlps-trigger pt-1"
                          href={seaPlanContent.ourPlansLink}
                        >
                          {seaPlanContent.ourPlansText}
                        </button>
                      </div>

                      {showComparePlansComponent}
                    </div>
                  </div>

                  <div className="slider">
                    <div className="jd-pricing-v2_dark text-center">
                      <header className="jd-pricing-v2__header__dark">
                        <img
                          className="img-fluid"
                          style={{ padding: "5px" }}
                          width="47%"
                          src={planImages.oceanPlanIcon}
                          alt="Ocean plan icon"
                        />
                        <div className="jd-pricing-v2__header__title">
                          <span>{oceanPlanContent.name}</span>
                        </div>
                        <span className="jd-card-headcopy col-lg-10">{oceanPlanContent.text}</span>
                        <span className="d-block">
                          <span>
                            <span>{data.plans.currency} </span>
                            <span className="jd-plans-prices">{oceanPlanContent.price}</span>
                          </span>
                          <span style={{ fontSize: ".8em" }}>/month</span>
                        </span>
                        <br />
                        <img
                          className="plans-section-divider"
                          style={{ display: "inline-block" }}
                          src={planImages.oceanPlanDivider}
                          alt="divider"
                        />
                      </header>

                      <div className="jd-pricing-v2__content__dark">
                        <ul className="list-unstyled mb-4">
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.design}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.revisions}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.brands}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.users}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.rightsIncluded}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.turnaround}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.dedicatedDesigner}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.dedicatedAccountManager}</li>
                          <li className="u-pricing-v2__list-item py-2">{oceanPlanContent.dedicatedWebDesigner}</li>
                        </ul>
                      </div>

                      <div className="jd-pricing-v2__cta">
                        <button
                          type="button"
                          className="btn rounded-pill jd-btn-primary--air vrlps-trigger pt-1"
                          href={oceanPlanContent.ourPlansLink}
                        >
                          {oceanPlanContent.ourPlansText}
                        </button>
                      </div>

                      {showComparePlansComponent}
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
